const dataCards = [
    {
      title: "Unlock the Power of Data:",
      description:
        "Learn how to create a data strategy that aligns with your business goals and drives measurable results.",
    },
    {
      title: "Master Data Governance:",
      description:
        "Gain insights into best practices for managing, securing, and governing your data assets effectively.",
    },
    {
      title: "Stay Compliant:",
      description:
        "Navigate data regulations with confidence and ensure your organization meets local and international standards.",
    },
    {
      title: "Network with Industry Experts:",
      description:
        " Connect with data professionals, thought leaders, and industry experts from across Kenya and beyond.",
    },
  ];
  
  const TrainingCards = () => (
    <div className="row h-75 p-5">
      {dataCards.map((card, index) => (
        <div className="col-md-6 p-2" key={index}>
          <div className="training-card h-100">
            <div className="d-flex align-items-center mb-3">
              <div className="dot-card me-3"></div>
              <h6 className="m-0">{card.title}</h6>
            </div>
            <p>{card.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
  
  export default TrainingCards;
  