import React from "react";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import useEmblaCarousel from "embla-carousel-react";
import { Link } from "react-router-dom";

const EmblaCarousel = () => {
  const options = { align: "start" };
  const blogImg1 =  {title:"Deed Dive Into Mixture Of Experts",img:"/assets/images/blog/blog-img-1.png",link:"/blog/deep-dive-into-mixture-of-experts"};
  const blogImg3 = {title:"The Rise, Fall and Renaissance of Data Governance",img:"/assets/images/blog/blog-img-3.png" ,link:"/blog/the-Rise-fall-and-renaissance-of-data-governance"};
  const blogImg2 = {title:"",img:"/assets/images/blog/blog-img-2.png"};
  const slides = [blogImg1, blogImg2, blogImg3, blogImg1, blogImg3];
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container user-select-none">
          {slides.map((slide, index) => (
            <div className="embla__slide" key={index}>
              {index !== 1 ? (
                <div
                  className="embla__slide__text text-start"
                >
                  <Link
                    className="link"
                    to={slide.link}
                  >
                    <h3 className="text-white">{slide.title}.</h3>
                  </Link>
                </div>
              ) : (
                <div className="embla__slide__text text-start mt-lg-5">
                  <h6 className="text-gray">2024</h6>
                  <h2 className="gradient-text">Training</h2>
                  <h6 className="text-gray2 mb-lg-5 pb-lg-5">
                    Join Technozor’s Exclusive Training for Data Professionals –
                    Nairobi, Kenya
                  </h6>
                  <Link className="link" to="/kenya-trip">
                    <span className="text-uppercase fs-3 fw-bold gradient-text">
                      View program
                      <img
                        src="/assets/images/program/Arrow 2.svg"
                        alt="arrow right"
                        className="ms-2"
                      />
                    </span>
                  </Link>
                </div>
              )}
              <img className="embla__slide__img" alt="test" src={slide.img} />
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      </div>
    </section>
  );
};

export default EmblaCarousel;
