import React from "react";
import EmblaCarousel from "../components/EmblaCarousel";
import GetInTouch from "../components/GetInTouch";

const Team = () => {
  const teamMembers = [
    {
      title: "Zied Boufaden",
      role: "Founder and CEO of Technozor",
      img: "assets/images/team/team-members/1.png",
    },
    {
      title: "Mohamed El Arbi Bassalah",
      role: "Data Consultant",
      img: "assets/images/team/team-members/2.png",
    },
    {
      title: "Fares Hergli",
      role: "Data Consultant",
      img: "assets/images/team/team-members/3.png",
    },
    {
      title: "Ghada Ben Amor",
      role: "Data Consultant",
      img: "assets/images/team/team-members/4.png",
    },
    {
      title: "Aymen Guehairia",
      role: "UX/UI Designer Specialist",
      img: "assets/images/team/team-members/5.png",
    },
    {
      title: "Feriel Garouachi",
      role: "Data Consultant",
      img: "assets/images/team/team-members/6.png",
    },
    {
      title: "Houssem Boukhris",
      role: "Business Analyst",
      img: "assets/images/team/team-members/10.png",
    },
    {
      title: "Nesrine Laffet",
      role: "Data Consultant",
      img: "assets/images/team/team-members/7.png",
    },
    {
      title: "Badis Laffet",
      role: "Data Consultant",
      img: "assets/images/team/team-members/8.png",
    },
    {
      title: "Med Khalil Ghazouani",
      role: "Data Consultant",
      img: "assets/images/team/team-members/9.png",
    },
  ];
  return (
    <>
      <div className="team-page container-fluid ">
        <div className="header">
          <div className="header-comp">
            <img
              className="header-img"
              src="/assets/images/team/header-img.png"
              alt="lightingcircle"
            />
            <h1 className="header-title">
              The Minds That Will Support Your Company
            </h1>
          </div>

          <img
            className="lightingcircle-1"
            src="/assets/images/background/lighting circle.png"
            alt="lightingcircle"
          />
          <img
            className="lightingcircle-2"
            src="/assets/images/background/lighting circle 2.png"
            alt="lightingcircle"
          />

          <img
            className="big-pattern"
            src="/assets/images/background/big-pattern.svg"
            alt="big-pattern"
          />
          <img
            className="colored-pattern"
            src="/assets/images/background/colored pattern.svg"
            alt="colored pattern"
          />
          <img
            className="colored-pattern-2"
            src="/assets/images/team/pattern.svg"
            alt="colored pattern"
          />
        </div>
        <div className="container">
          <div className="py-5">
            <div className="row d-flex justify-content-center pb-5">
              <div className="col-md-10 d-flex flex-column align-items-start align-items-md-center">
                <div>
                  <h6 className="text-uppercase">TECHNOZOR TEAM</h6>
                  <h2 className="mb-4 gradient-text">
                    How Our Specialists Help You.
                  </h2>
                </div>
                <div className="line mt-3"></div>
                <div className="col-md-8 d-flex flex-column align-items-start align-items-md-center">
                  <p className="mt-5 text-left">
                    Our team of data science and business experts is dedicated
                    to guiding your organization through its data transformation
                    journey, equipping you with the analytical capabilities
                    needed for an optimized data value chain. They will assess
                    your specific requirements and provide a comprehensive
                    report to streamline and support the adoption of a robust
                    big data solution.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {teamMembers.map((member, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                <TeamMemberCard
                  title={member.title}
                  role={member.role}
                  img={member.img}
                />
              </div>
            ))}
          </div>
          <div className="line my-5"></div>
          {/* <Blog /> */}
          <h2 className="gradient-text3 my-5">Blog</h2>
          <EmblaCarousel />
          <div className="line mt-5"></div>
          <GetInTouch />
        </div>
      </div>
    </>
  );
};

const TeamMemberCard = ({ title, role, img }) => {
  return (
    <div className="d-flex align-items-center flex-column p-5 px-md-5">
      <div className="team-member-card d-flex align-items-center flex-column">
        <img className="w-100" src={img} alt={`img-${title}`}></img>
        <h3 className="pt-4 fs-1 fw-bold text-center">{title}</h3>
        <p className="px-2">{role}</p>
      </div>
    </div>
  );
};

export default Team;
