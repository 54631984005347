import React, { useEffect, useState, useRef } from "react";
import EmblaCarousel from "../components/EmblaCarousel";
import { Link } from "react-router-dom";
import { Services } from "../components/Services";
import GetInTouch from "../components/GetInTouch";

const Home = () => {
  const scrollToRef = useRef(null);
  const handleScroll = () => {
    const offset = window.innerWidth < 992 ? -50 : 0;
    window.scrollTo({
      top: scrollToRef.current.offsetTop + offset,
      behavior: "smooth",
    });
  };

  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" || localStorage.getItem("darkMode") === null ? true : false
  );

  useEffect(() => {
    const updateDarkMode = () => {
      setIsDarkMode(localStorage.getItem("darkMode") === "true");
    };
    window.addEventListener("darkModeToggle", updateDarkMode);

    return () => window.removeEventListener("darkModeToggle", updateDarkMode);
  }, []);

  return (
    <>
      {/* Main Banner */}
      <div className="home-page container-fluid">
        <div className="header">
          <div className="header-comp">
            <h1 className="header-title">
              Transform Your Company <br />
              <span className="gradient-text">With Data-Driven</span> <br />
              Strategies.
            </h1>
            <img
              className="arrow mb-5"
              src="/assets/images/program/Arrow 1.svg"
              alt="arrow down"
              onClick={handleScroll}
              style={{ cursor: "pointer" }}
            />
          </div>

          <img
            className="ellipse-1"
            src="/assets/images/home/Ellipse 1.png"
            alt="lightingcircle"
          />
          <img
            className="ellipse-2"
            src="/assets/images/home/Ellipse 2.png"
            alt="lightingcircle"
          />
          <img
            className="ellipse-3"
            src="/assets/images/home/Ellipse 3.png"
            alt="lightingcircle"
          />
          <img
            className="ellipse-4"
            src="/assets/images/home/Ellipse 4.png"
            alt="lightingcircle"
          />
          <img
            className="ellipse-5"
            src="/assets/images/home/Ellipse 5.png"
            alt="lightingcircle"
          />
          <img
            className="lightingcircle-1"
            src="/assets/images/home/lighting circle.png"
            alt="lightingcircle"
          />

          <img
            className="big-pattern"
            src="/assets/images/background/big-pattern.svg"
            alt="big-pattern"
          />
          <img
            className="colored-pattern"
            src="/assets/images/background/colored pattern.svg"
            alt="colored pattern"
          />
          <img
            className="colored-pattern-2"
            src="/assets/images/home/pattern 1.png"
            alt="lightingcircle"
          />
          <img
            className="colored-pattern-3"
            src="/assets/images/home/pattern 2.png"
            alt="lightingcircle"
          />
          <img
            className="colored-pattern-4"
            src="/assets/images/home/pattern 3.png"
            alt="lightingcircle"
          />
          <img
            className="colored-pattern-5"
            src="/assets/images/home/pattern 4.png"
            alt="lightingcircle"
          />
        </div>
      </div>

      <div ref={scrollToRef} className="scroll-target-section">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="align-self-center home-comp">
              <img
                alt="advanced-analytics-img"
                src="/assets/images/home/home1.png"
                className="d-block w-100"
              />
              <h2 className="comp-title mt-3">
                Advanced Analytics <br />
                For The Enterprise
              </h2>
            </div>
            <div className="mt-5 comp-p px-md-0 px-5">
              <p className="col-lg-4  dark-blue ">
                With deep expertise in both data science and real-world business
                applications, we empower organizations to harness the full
                potential of their data, providing a competitive edge in an
                increasingly data-driven world.
              </p>
              <Link className="link" to="/services">
                <span className="text-uppercase fs-3 fw-bold gradient-text">
                  View services
                  <img
                    src="/assets/images/program/Arrow 2.svg"
                    alt="arrow right"
                    className="ms-2"
                  />
                </span>
              </Link>
            </div>
          </div>

          <div className="p-5 m-lg-5"></div>
          <div className="p-lg-5 m-lg-5"></div>

          {/* About*/}
          <div className="ms-md-5 my-lg-5 row d-flex align-items-center">
            <div className="gradient-text col-lg-7 mb-5 mb-md-0">
              <h2>
                About
                <br />
                Technozor AI
              </h2>
            </div>
            <div className="col-lg-4 dark-blue">
              <p>
                Technozor aims to rethink and optimize organisation using
                analytics toolset to assess organization functions. The goals is
                to help our customer augement their value proposition by
                designing an end to end data strategy covering process, people
                and technologies.
              </p>
            </div>
          </div>

          <div className="p-5"></div>

          <div className="row my-5 px-5 pe-md-0">
            <div className="col-md-6 ps-sm-5 order-md-2 d-flex flex-column justify-content-center mb-5 ">
              <h6 className="gradient-text text-uppercase mb-3">Technozor</h6>
              <h3 className="gradient-text mb-md-5">
                Our
                <br />
                Approach
              </h3>
              <ul className="p-4 dark-blue mb-md-5 w-md-75">
                <li>
                  <p>
                    Understanding your business problems and designing the most
                    effective data solution to fit.
                  </p>
                </li>
                <li>
                  <p>
                    Aligning the data strategy to your organization's mission
                    and strategic goals.
                  </p>
                </li>
                <li>
                  <p>
                    Re-engineering your business process and integrating data
                    products for efficient decision-making.
                  </p>
                </li>
              </ul>
              {/* <Link
                className="link mt-lg-3"
                to="/"
                style={{ width: "fit-content" }}
              >
                <span className="text-uppercase fs-3 fw-bold gradient-text w-auto">
                  View
                  <img
                    src="/assets/images/program/Arrow 2.svg"
                    alt="arrow right"
                    className="ms-2"
                  />
                </span>
              </Link> */}
            </div>
            <div className="col-md-6 order-md-1 ps-md-5">
              <div className="image-container">
                <img
                  src="/assets/images/home/home2.png"
                  alt="Technozor Robot"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>

          <div className="p-3 "></div>

          <div className="row my-5 px-5 pe-md-0">
            <div className="col-md-6 d-flex flex-column justify-content-center mb-5 ps-md-5">
              <h6 className="gradient-text2 text-uppercase mb-3">Technozor</h6>
              <h3 className="gradient-text mb-md-5 mb-sm-5">Mission</h3>
              <p className="pt-5 pe-5 dark-blue mb-5 w-md-75">
                TECHNOZOR helps organizations navigate their data transformation
                journey by equipping them with the analytical capabilities
                needed to optimize their data value chain. We reduce cost and
                complexity through innovative, adaptable, and automated
                processes, platforms, and knowledge management systems.
              </p>
              {/* <Link
                className="link mt-lg-5"
                to="/"
                style={{ width: "fit-content" }}
              >
                <span className="text-uppercase fs-3 fw-bold gradient-text w-auto">
                  View
                  <img
                    src="/assets/images/program/Arrow 2.svg"
                    alt="arrow right"
                    className="ms-2"
                  />
                </span>
              </Link> */}
            </div>
            <div className="col-md-6 ">
              <div className="image-container">
                <img
                  src="/assets/images/home/home3.png"
                  alt="Technozor Robot"
                  className="img-fluid rounded"
                />
              </div>
            </div>
          </div>

          {/* Customers */}
          <div className="p-5 m-lg-5"></div>
          <div className="line my-5"></div>
          <div className="">
            <h2 className="dark-blue mb-5">Our Customers and Partners</h2>
            <div className="container mt-5">
              <div className="row justify-content-center p-5">
                {Array.from(Array(9), (e, index) => {
                  return (
                    <div
                      className="col-12 col-md-4 col-lg-3 d-flex align-items-center justify-content-center customers mb-4"
                      key={index}
                    >
                      <img
                        src={`/assets/images/customers/${
                          !isDarkMode ? "image" : "banner"
                        } ${index + 1}.png`}
                        alt={`Customer ${index + 1}`}
                        className="img-fluid p-5"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="line my-5"></div>
          <div className="container pt-5 pt-md-0">
            {/* Services */}
            <Services />
          </div>
          <div className="line my-5"></div>
          {/* Blog */}
          <div className="mb-5 ">
            <h2 className="gradient-text3 mb-5">Blog</h2>
            <EmblaCarousel />
          </div>
          <div className="line mt-5"></div>
          <GetInTouch />
        </div>
      </div>
    </>
  );
};

export default Home;
