import React from "react";


const About = () => {

    return (
          <div className="container">
            <div className="my-5 row d-flex align-items-center justify-content-center">
              <div className="col-lg-7 mb-5">
                <h2 className="gradient-text mb-5">
                  Start Your Data
                  <br />
                  Transformation
                </h2>
              </div>
              <div className="col-lg-5 dark-blue">
                <p>
                  Ready to drive your enterprise forward with advanced
                  analytics?
                  <br />
                  Contact us today to explore how Technozor can help you
                  transform your data into a competitive advantage.
                </p>
              </div>
            </div>
          </div>
    )
}

export default About;