const ServicePhase = ({ phase }) => {
  return (
    <div className="row" style={{ paddingTop: "70px", paddingBottom: "70px" }}>
      <div className="col-12 col-lg-8">
        <h6 className="text-uppercase">{phase.phase}</h6>
        <h2 className="gradient-text2">{phase.title}</h2>
        <div className="py-5">
          <p>{phase.description.desc}</p>
          <ul className="ps-5">
            {phase.description.list.map((text, index) => (
              <li key={index}><span className="fs-4">{text}</span></li>
            ))}
          </ul>
        </div>
      </div>

      <img src={phase.image} alt="Data graph" className="pt-5 w-100" />
    </div>
  );
};

export default ServicePhase;
