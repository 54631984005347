import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const toggleSlide = (el, display = "block") => {
  var el_max_height = 0;

  if (el.getAttribute("data-max-height")) {
    if (el.style.maxHeight.replace("px", "").replace("%", "") === "0") {
      el.style.maxHeight = el.getAttribute("data-max-height");
    } else {
      el.style.maxHeight = "0";
    }
  } else {
    el_max_height = getHeight(el) + "px";
    el.style["transition"] = "max-height 0.5s ease-in-out";
    el.style.overflowY = "hidden";
    el.style.maxHeight = "0";
    el.setAttribute("data-max-height", el_max_height);
    el.style.display = display;
    setTimeout(function () {
      el.style.maxHeight = el_max_height;
    }, 10);
  }
};

const navigation_responsive = () => {
  const width = window.innerWidth;
  const navigation = document.querySelector(".navigation");

  if (typeof navigation === "undefined" || navigation === null) return;

  if (width < 992) {
    navigation.classList.add("responsive");
  } else {
    navigation.classList.remove("responsive");
  }
};

const getHeight = (el) => {
  var el_style = window.getComputedStyle(el),
    el_display = el_style.display,
    el_position = el_style.position,
    el_visibility = el_style.visibility,
    el_max_height = el_style.maxHeight.replace("px", "").replace("%", ""),
    wanted_height = 0;

  if (el_display !== "none" && el_max_height !== "0") {
    return el.offsetHeight;
  }

  el.style.position = "absolute";
  el.style.visibility = "hidden";
  el.style.display = "block";

  wanted_height = el.offsetHeight;

  el.style.display = el_display;
  el.style.position = el_position;
  el.style.visibility = el_visibility;

  return wanted_height;
};

const Header = () => {
  const navigationRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem("darkMode");
    return savedMode === null ? true : savedMode === "true";
  });
  const location = useLocation();

  useEffect(() => {
    const navigationElement = navigationRef.current;

    window.addEventListener("resize", navigation_responsive);
    window.addEventListener("load", navigation_responsive);

    navigationElement
      .querySelector(".navigation-bar")
      .addEventListener("click", () => {
        navigationElement.classList.add("shown");
      });

    Array.from(navigationElement.querySelectorAll(".has-child")).forEach(
      (element) => {
        element.addEventListener("click", (e) => {
          if (window.innerWidth > 992) return;
          if (
            e.target.parentElement.parentElement.classList.contains(
              "has-child"
            ) ||
            e.target.parentElement.parentElement.classList.contains("parent")
          ) {
            e.preventDefault();
          }

          if (e.currentTarget.classList.contains("dropped")) {
            toggleSlide(e.currentTarget.querySelector(".child"));
            e.currentTarget.classList.remove("dropped");
            return;
          }

          Array.from(
            e.currentTarget.parentElement.querySelectorAll(".has-child.dropped")
          ).forEach((e) => {
            e.classList.remove("dropped");
            toggleSlide(e.querySelector(".child"));
          });

          e.currentTarget.classList.add("dropped");
          toggleSlide(e.currentTarget.querySelector(".child"));
        });
      }
    );

    const close_button = navigationElement.querySelector(".close-button");

    close_button.addEventListener("click", () => {
      const dropped = navigationElement.querySelector(".dropped");

      if (dropped) {
        toggleSlide(dropped.querySelector(".child"));
        dropped.classList.remove("dropped");
      }

      navigationElement.classList.remove("shown");
    });

    const closeMenu = () => {
      navigationElement.classList.remove("shown");
    };

    Array.from(navigationElement.querySelectorAll(".parent li a")).forEach(
      (link) => {
        link.addEventListener("click", closeMenu);
      }
    );

    const mobileHeaderCloseButton = navigationElement.querySelector(
      ".mobile-header .close-button"
    );
    const mobileHeaderLogo = navigationElement.querySelector(
      ".mobile-header .logo a"
    );

    if (mobileHeaderCloseButton) {
      mobileHeaderCloseButton.addEventListener("click", closeMenu);
    }
    if (mobileHeaderLogo) {
      mobileHeaderLogo.addEventListener("click", closeMenu);
    }

    // Scroll to top on same page navigation
    Array.from(navigationElement.querySelectorAll("a")).forEach((link) => {
      link.addEventListener("click", (e) => {
        if (link.getAttribute("href") === location.pathname) {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      });
    });

    return () => {
      Array.from(navigationElement.querySelectorAll(".parent li a")).forEach(
        (link) => {
          link.removeEventListener("click", closeMenu);
        }
      );

      if (mobileHeaderCloseButton) {
        mobileHeaderCloseButton.removeEventListener("click", closeMenu);
      }
      if (mobileHeaderLogo) {
        mobileHeaderLogo.removeEventListener("click", closeMenu);
      }

      window.removeEventListener("resize", navigation_responsive);
      window.removeEventListener("load", navigation_responsive);
    };
  }, [location.pathname]);

  useEffect(() => {
    navigationRef.current.classList.remove("shown");
    document.documentElement.classList.toggle("dark-mode", isDarkMode);
    localStorage.setItem("darkMode", isDarkMode);
    window.dispatchEvent(new Event("darkModeToggle"));
  }, [isDarkMode]);

  return (
    <>
      <div className="navigation" ref={navigationRef}>
        <div className="navigation-wrapper">
          <div className="navigation-inner">
            <div className="navigation-logo">
              <Link to="/">
                <img
                  className="logo"
                  src="/assets/images/logo.png"
                  alt="technozor-logo"
                />
                <span className="title">TECHNOZOR</span>
              </Link>
            </div>
            <div className="navigation-menu">
              <div className="mobile-header">
                <div className="logo">
                  <Link to="/">
                    <img src="/assets/images/logo.png" alt="technozor-white" />
                  </Link>
                </div>
                <ul>
                  <li className="close-button">
                    <i className="fas fa-times"></i>
                  </li>
                </ul>
              </div>
              <ul className="parent">
                <li>
                  <Link className="link" to="/">
                    <span className="text-uppercase">home</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/services">
                    <span className="text-uppercase">Services</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/team">
                    <span className="text-uppercase">Team</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="/kenya-trip">
                    <span className="text-uppercase">Kenya Trip</span>
                  </Link>
                </li>
                <li>
                  <Link className="link" to="#">
                    <span className="text-uppercase">Trends & Insights</span>
                  </Link>
                </li>

                <li onClick={() => setIsDarkMode((prev) => !prev)}>
                  <div className={`toggle-button ${isDarkMode ? "dark" : ""}`}>
                    <div className="icon">
                      <img
                        className="toggle-dark"
                        src={`/assets/images/icons/${
                          !isDarkMode ? "light-on.svg" : "light-off.svg"
                        }`}
                        alt={!isDarkMode ? "light-on" : "light-off"}
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="navigation-bar">
              <span className="text-uppercase">menu</span>
              <img src="/assets/images/icons/menu.svg" alt="menu" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
