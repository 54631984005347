import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Spinner from "./Spinner";
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';

const MarkdownRenderer = ({ markdownPath }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    fetch(markdownPath)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Markdown not found");
        }
        return response.text();
      })
      .then((text) => {
        setContent(text);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [markdownPath]);

  return (
    <div className="markdown-renderer">
      {loading ? (
        <Spinner />
      ) : error ? (
        <h2 className="gradient-text text-center my-5">Not Found</h2>
      ) : (
        <ReactMarkdown
          className="markdown-content"
          remarkPlugins={[remarkMath]}
          rehypePlugins={[rehypeKatex]}
          components={{
            h1: ({ node, children, ...props }) =>
              children && children.length > 0 ? <h2 className="gradient-text" {...props}>{children}</h2> : null,
            h2: ({ node, children, ...props }) =>
              children && children.length > 0 ? <h3 className="gradient-text" {...props}>{children}</h3> : null,
            h3: ({ node, children, ...props }) =>
              children && children.length > 0 ? <h4 className="gradient-text" {...props}>{children}</h4> : null,
            h4: ({ node, children, ...props }) =>
              children && children.length > 0 ? <h5 className="gradient-text" {...props}>{children}</h5> : null,
            h5: ({ node, children, ...props }) =>
              children && children.length > 0 ? <h6 className="gradient-text" {...props}>{children}</h6> : null,
            h6: ({ node, children, ...props }) =>
              children && children.length > 0 ? <p className="gradient-text" {...props}>{children}</p> : null,
            a: ({ node, children, ...props }) => (
              <a className="link link-t" {...props}>
                <span>{children}</span>
              </a>
            ),
          }}          
        >
          {content}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default MarkdownRenderer;
