import React from "react";
import Tag from "./Tag";
import MarkdownRenderer from "./MarkdownRenderer";



const BlogContent = ({ blog }) => {
    const markdown = blog.markdown;
  return (
    <>
      <div className="line my-5"></div>
      <div className="container blog-content d-flex align-items-center">
        <img alt="blog-img" className="w-75" src={blog?.headerImg} />
        <div className="cards">
          <div className="custom-card">
            <h4>{blog?.writer}</h4>
            <div className="tag-comp">
              <Tag tagName="writer" />
            </div>
          </div>
          <div className="custom-card">
            <h4>{blog?.reviewer}</h4>
            <div className="tag-comp">
              <Tag tagName="reviewer" />
            </div>
          </div>
        </div>
      </div>
      <div className="markdown-content my-5">
        <MarkdownRenderer markdownPath={markdown} />
      </div>
    </>
  );
};

export default BlogContent;
