import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EmblaCarousel from "../components/EmblaCarousel";
import GetInTouch from "../components/GetInTouch";
import BlogContent from "../components/BlogContent";
import Tag from "../components/Tag";

const Blog = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  
  const blogs = [
    {
      link: "deep-dive-into-mixture-of-experts",
      headerImg: "/assets/markdown/blog1/blog-header.png",
      headerTitle: "Deed Dive Into Mixture Of Experts",
      writer: "Feriel Garouachi",
      reviewer: "Feriel Garouachi",
      markdown: "/assets/markdown/blog1/deep_Deep_dive_into_mixture_of_experts.md",
      tags: ["tags", "tags", "tags", "tags"],
    },
    {
      link: "the-Rise-fall-and-renaissance-of-data-governance",
      headerImg: "/assets/markdown/blog2/blog-header.png",
      headerTitle: "The Rise, Fall and Renaissance of Data Governance",
      writer: "Ghada Ben Amor",
      reviewer: "Ghada Ben Amor",
      markdown: "/assets/markdown/blog2/the_rise_fall_and_renaissance_of_data_governance.md",
      tags: ["tags", "tags", "tags", "tags"],
    },
  ];
  
  useEffect(() => {
    const matchedBlog = blogs.find((blog) => blog.link === slug);
    if (matchedBlog) {
      setBlog(matchedBlog);
    } else {
      setBlog(null);
    }
    // eslint-disable-next-line
  }, [slug]); 
  

  return (
    <>
      <div className="blog-page container-fluid ">
        <div className="header">
          <div className="header-comp">
            <img
              className="header-img"
              src="/assets/images/blog/header-img.png"
              alt="lightingcircle"
            />
            <h1 className={`header-title${blog ? "": "-not-found"} text-capitalize`}>
              {blog ? blog.headerTitle : "Not Found"}
            </h1>

            <div className="row tag-comp">
              {blog?.tags.map((tag, index) => (
                <Tag tagName={tag} key={index} />
              ))}
            </div>
          </div>

          <img
            className="lightingcircle-1"
            src="/assets/images/background/lighting circle.png"
            alt="lightingcircle"
          />
          <img
            className="lightingcircle-2"
            src="/assets/images/background/lighting circle 2.png"
            alt="lightingcircle"
          />

          <img
            className="big-pattern"
            src="/assets/images/background/big-pattern.svg"
            alt="big-pattern"
          />
          <img
            className="colored-pattern"
            src="/assets/images/background/colored pattern.svg"
            alt="colored pattern"
          />
        </div>
        <div className="container">
          {blog && <BlogContent blog={blog} />}
          <div className="line my-5"></div>
          <h2 className="gradient-text3 my-5">Blog</h2>
          <EmblaCarousel />
          <div className="line mt-5"></div>
          <GetInTouch />
        </div>
      </div>
    </>
  );
};

export default Blog;
