// Router.js
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import CustomScrollbar from './components/CustomScrollbar';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// SCSS
import './scss/style.scss';

// JS
import 'bootstrap/dist/js/bootstrap.min.js';

const Router = () => {
  return (
    <BrowserRouter>
      <CustomScrollbar />
      <App />
    </BrowserRouter>
  );
};

export default Router;
