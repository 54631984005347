import React from "react";

const Tag = ({tagName}) => {
  return (
    <div className="col-lg-4 col-md-6 col-6 tag">
      <div className="tag-elipse"/>
      <span className="tag-title gradient-text text-uppercase">{tagName}</span>
    </div>
  );
};

export default Tag;
