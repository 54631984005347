import React, { useState } from "react";

const GetInTouch = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        setFormData({ name: "", email: "", message: "" });
        alert("Message sent successfully!");
      } else {
        alert("Error sending message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error submitting the form.");
    }
  };

  return (
    <div className="container pt-5">
      <div className="my-5 row d-flex align-items-center justify-content-center">
        <div className="col-lg-7 mb-5">
          <h2 className="gradient-text mb-5">
            Start your Digital
            <br />
            Transformation Journey
            <br />
            With Technozor.
          </h2>
          {/* <p className="w-75">
            If your research processes have unique features, and you are looking
            for an individual funding solution - we can help.
          </p> */}
        </div>
        <div className="col-lg-5">
          <form onSubmit={handleSubmit}>
            <div className="custom-input-container position-relative mb-5">
              <input
                type="text"
                name="name"
                className="form-control custom-input"
                placeholder="Name*"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="custom-input-container position-relative mb-5">
              <input
                type="email"
                name="email"
                className="form-control custom-input"
                placeholder="Email*"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="custom-input-container position-relative mb-5">
              <input
                type="text"
                name="message"
                className="form-control custom-input"
                placeholder="Message*"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button
              className="link mt-4 ps-5 bg-transparent border-0"
              type="submit"
              style={{ width: "fit-content" }}
            >
              <span className="text-uppercase fs-3 fw-bold gradient-text w-auto ">
                GET IN TOUCH
                <img
                  src="/assets/images/program/Arrow 2.svg"
                  alt="arrow right"
                  className="ms-2"
                />
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
