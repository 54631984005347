import React from "react";

import ServicePhase from "../components/ServicePhase";
import EmblaCarousel from "../components/EmblaCarousel";
import GetInTouch from "../components/GetInTouch";
import About from "../components/About";

const Service = () => {
  const phases = [
    {
      phase: "phase 1",
      title: "Data Strategy",
      description: {
        desc: "We create customized data strategies to help your enterprise extract the maximum value from its data. Our data strategy services include:",
        list: [
          "Enterprise Data Strategy: Designing a roadmap for managing and utilizing data across the organization.",
          "Data Management Office Setup: Establishing governance structures and operational frameworks for effective data management.",
        ],
      },
      image: "/assets/images/program/image-phase-1.png",
    },
    {
      phase: "phase 2",
      title: "Data Foundation",
      description: {
        desc: "Building a strong data foundation is crucial to enabling your digital transformation. Our services focus on:",
        list: [
          "Data Platform: Implementing scalable platforms that can support your organization's data needs.",
          "Data Operations: Automating and streamlining data workflows for seamless integration across business functions.",
          "Data Compliance & Security: Ensuring your data is secure, compliant with regulations, and accessible for the right users.",
        ],
      },
      image: "/assets/images/program/image-phase-2.png",
    },
    {
      phase: "phase 3",
      title: "Data Insight",
      description: {
        desc: "We help you transform raw data into actionable insights, enabling informed decision-making and driving business growth:",
        list: [
          "Data Products: Developing tailored data products that deliver insights aligned with your business objectives.",
          "Data Visualization: Creating intuitive and impactful visualizations that make complex data accessible to all stakeholders.",
          "Business Impact: Identifying how data insights translate into measurable business outcomes.",
        ],
      },
      image: "/assets/images/program/image-phase-3.png",
    },
  ];
  return (
    <>
      {/* Bannière principale */}
      <div className="program-page container-fluid ">
        <div className="header">
          <div className="header-comp">
            <img
              className="header-img"
              src="/assets/images/program/header-img.png"
              alt="lightingcircle"
            />
            <h1 className="header-title">
              Advanced Analytics For The Entreprise
            </h1>
          </div>

          <img
            className="lightingcircle-1"
            src="/assets/images/background/lighting circle.png"
            alt="lightingcircle"
          />
          <img
            className="lightingcircle-2"
            src="/assets/images/background/lighting circle 2.png"
            alt="lightingcircle"
          />

          <img
            className="big-pattern"
            src="/assets/images/background/big-pattern.svg"
            alt="big-pattern"
          />
          <img
            className="colored-pattern"
            src="/assets/images/background/colored pattern.svg"
            alt="colored pattern"
          />
          <img
            className="colored-pattern-2"
            src="/assets/images/background/colored pattern 2.svg"
            alt="colored pattern"
          />
          <img
            className="colored-pattern-3"
            src="/assets/images/background/colored pattern 3.svg"
            alt="colored pattern"
          />
        </div>
        <div className="container">
          <div className="text-center p-5 px-md-5 px-0">
            <div className="description">
              <div className="line"></div>
              <p className="paragraph">
                At Technozor, we offer comprehensive solutions designed to
                transform your business by leveraging the power of data
                analytics. From initial strategy development to monetizing
                insights, we guide you through every step of your data journey.
              </p>
              <p className="paragraph">
                We break the 180 days into 3 phases that help us rapidly scale
                our collaboration. This starts with a deep dive, and quickly
                ramps to hands-on engagement both remotely and in our offices
                around the world.
              </p>
              <div className="line"></div>
            </div>
          </div>
          <div className="py-5">
            <div className="row d-flex justify-content-center pb-5">
              <div className="col-md-10 d-flex flex-column align-items-start align-items-md-center">
                <div>
                  <h6 className="text-uppercase">Services</h6>
                  <h2 className="mb-4 gradient-text">
                    How We Can Help Your Enterprise
                  </h2>
                </div>
                <p className="mb-5 text-center">
                  Our process is tailored to meet the unique needs of your
                  organization:
                </p>
              </div>
            </div>

            <div className="row pb-5">
              <div className="col-md-4" style={{ padding: "4em 5em 0px 5em" }}>
                <h3 className="gradient-text">Custom Data Solutions</h3>
                <div className="line mb-3"></div>
                <p className="custom-p">
                  Understanding Your Business Problems And Designing The Most
                  Effective Data Solutions To Address Them.
                </p>
              </div>
              <div className="col-md-4" style={{ padding: "4em 5em 0px 5em" }}>
                <h3 className="gradient-text">Strategic Data Alignment</h3>
                <div className="line mb-3"></div>
                <p className="custom-p">
                  Aligning The Data Strategy To Your Organization’s Mission And
                  Strategic Goals.
                </p>
              </div>

              <div className="col-md-4" style={{ padding: "4em 5em 0px 5em" }}>
                <h3 className="gradient-text">Data-Driven Efficiency</h3>
                <div className="line mb-3"></div>
                <p className="custom-p">
                  Re-Engineering Your Business Processes And Integrating Data
                  Products For Efficient Decision-Making.
                </p>
              </div>
            </div>
          </div>
          {phases.map((phase, index) => (
            <ServicePhase key={index} phase={phase} />
          ))}
          {/* Blog */}
          <h2 className="gradient-text3 mb-5">Blog</h2>
          <EmblaCarousel />
          <div className="pt-5"></div>
          <div className="line my-5"></div>
          <div className="pt-2"></div>
          {/* About*/}
          <About/>
          <div className="mt-5"></div>
          {/* GetInTouch*/}
          <GetInTouch />
        </div>
      </div>
    </>
  );
};

export default Service;
