import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CustomScrollbar = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const navigation = document.querySelector('.navigation');

            if (!navigation) return;

            if (window.innerHeight > 991 && scrollPosition > 53) {
                navigation.classList.add('scrolled');
            } else if (window.innerHeight < 991 && scrollPosition > 30) {
                navigation.classList.add('scrolled');
            } else {
                navigation.classList.remove('scrolled');
            }
        };
        window.addEventListener('scroll', handleScroll);

        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pathname]);

    return null;
};

export default CustomScrollbar;
