import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Preloader from "./components/Preloader";
import Service from "./pages/Service";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Blog from "./pages/Blog";
// import Trends from "./pages/Trends";
import Training from "./pages/Training";

const App = () => {
  return (
    <>
      <div className="main-wrapper">
        <Preloader />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
           <Route path="/services" element={<Service />} />
          <Route path="/team" element={<Team />} />
          <Route path="/blog/:slug" element={<Blog />} />
          {/* <Route path="/trends-and-insights" element={<Trends />} /> */}
          <Route path="/kenya-trip" element={<Training />} />

          <Route path="*" element={<div>Page Not Found</div>} />
        </Routes>
        <Footer />
      </div>
    </>
  );
};

export default App;
