import React, { useEffect, useState } from "react";

export const Services = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" || localStorage.getItem("darkMode") === null ? true : false
  );

  useEffect(() => {
    const updateDarkMode = () => {
      setIsDarkMode(localStorage.getItem("darkMode") === "true");
    };
    window.addEventListener("darkModeToggle", updateDarkMode);

    return () => window.removeEventListener("darkModeToggle", updateDarkMode);
  }, []);

  return (
    <div className="service row mt-5 ">
      <div className="col-lg-6 order-lg-2 p-5 align-self-center relative">
        <div className="gradient-card">
          <img
            src={`assets/images/home/${!isDarkMode ? "dark-pattern.svg" : "pattern.svg"}`}
            alt="Wave Pattern"
            className="d-block pattern"
          />
          <div className="card-component">
            <div>
              <h2 className="text-white pb-4">Services</h2>
              <p className="text-white">
                We offer a wide range of services, from data strategy, data
                foundation, data insight to data monetization. We have a team of
                experts that we put at your disposal, they will study your
                requirements and deliver you a comprehensive report that will
                greatly facilitate the adoption of a big data solution.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 order-lg-1 gradient-border p-5 align-self-center">
        <div className="row">
          <div className="col-lg-6 col-sm-6 service-padding">
            <ServiceCard title="Data Strategy" desc="Works jointly with key business stakeholders during their data strategy transformation and designs the data center of excellence." img="assets/images/home/service-img-1.png"/>
            <ServiceCard title="Data Governance" desc="Helps you build and design data management and governance practices to control and manage your organization's data assets." img="assets/images/home/service-img-2.png"/>
          </div>

          <div className="col-lg-6 col-sm-6">
            <ServiceCard title="Data Platform" desc="Assesses the technological landscape and designs the target state of data capabilities required to automate your data value chain." img="assets/images/home/service-img-3.png"/>
            <ServiceCard title="AI Use Cases" desc="Designs, implements, and assesses AI systems across your organization needed for smart product and digital service design." img="assets/images/home/service-img-4.png"/>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceCard = ({ title, desc,img }) => {
  return (
    <div className="d-flex align-items-center flex-column mb-5 p-2 px-5 px-md-0">
      <img
        className="p-5 p-sm-3 w-100"
        src={img}
        alt="service-img"
      ></img>
      <h3 className="pt-3 fs-1 fw-bold dark-blue">{title}</h3>
      <p className="px-5 px-md-3 text-gray2 text-center">{desc}</p>
    </div>
  );
};
