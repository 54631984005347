import React from "react";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="footer-content">
                <div className="row">
                  <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
                    <Link to="https://twitter.com/technozor" className="link">
                      <h6 className="text-uppercase">twitter</h6>
                    </Link>
                  </div>
                  <div className="col-12 col-md-6 mb-md-0 text-center">
                    <Link to="https://www.linkedin.com/company/technozor" className="link">
                      <h6 className="text-uppercase">linkedin</h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="footer-content">
                <div className="row">
                  <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
                    <Link to="/not-set" className="link">
                      <h6 className="text-uppercase">cookies policy</h6>
                    </Link>
                  </div>
                  <div className="col-12 col-md-6 mb-3 mb-md-0 text-center">
                    <Link to="/not-set" className="link">
                      <h6 className="text-uppercase">privacy & policy</h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="line my-5"></div>
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <h6 className="copyright txt-sm c-grey text-uppercase">
                all rights reserved by <span className="gradient-text">TECHNOZOR</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
