import React, { useEffect, useState } from "react";
import GetInTouch from "../components/GetInTouch";
// import ImageSlider from "../components/ImageSlider";
import { Link } from "react-router-dom";
import TrainingCards from "../components/TrainingCards";

const Training = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("darkMode") === "true" || localStorage.getItem("darkMode") === null ? true : false
  );
  useEffect(() => {
    const updateDarkMode = () => {
      setIsDarkMode(localStorage.getItem("darkMode") === "true");
    };
    window.addEventListener("darkModeToggle", updateDarkMode);

    return () => window.removeEventListener("darkModeToggle", updateDarkMode);
  }, []);
  return (
    <>
      <div className="training-page container-fluid">
        <div className="header">
          <div className="header-comp">
            <img
              className="header-img"
              src="/assets/images/training/header-img.png"
              alt="header-img"
            />
            <div className="header-text">
              <h1 className="header-title">
                Empower Your Data Journey: <br />
                Master Data Strategy & Governance in Kenya
              </h1>
              <p className="header-subtitle">
                Join Technozor’s Exclusive Training for Data Professionals –
                Nairobi, Kenya
              </p>
            </div>
          </div>

          <img
            className="lightingcircle-1"
            src="/assets/images/background/lighting circle.png"
            alt="lightingcircle"
          />
          <img
            className="lightingcircle-2"
            src="/assets/images/background/lighting circle 2.png"
            alt="lightingcircle"
          />

          <img
            className="big-pattern"
            src="/assets/images/background/big-pattern.svg"
            alt="big-pattern"
          />
          <img
            className="colored-pattern"
            src="/assets/images/background/colored pattern.svg"
            alt="colored pattern"
          />
        </div>
        <div className="header-content container-xxl">
          <div className="line my-5"></div>
          <h4>
            Technozor, a leading data and digital transformation consulting
            firm, is expanding its footprint in Kenya, and we’re excited to kick
            off our journey with a high-impact training session tailored for
            Kenya’s data professionals. Join us for an exclusive workshop
            designed to equip you with the skills to drive data strategy and
            governance in your organization.
          </h4>
        </div>
      </div>
      {/* <div
        className="d-flex my-5"
        style={{ maxWidth: "2500px", margin: "0 auto" }}
      >
        <div className="slider-bar"></div>
        <div className="slider-bar"></div>
        <div className="slider-bar"></div>
        <ImageSlider />
      </div> */}

      <div className="container" style={{ marginTop: "5rem" }}>
        <div className="row align-items-stretch gradient-border-training horizontal">
          <div className="col-md-7 d-flex flex-column justify-content-center gradient-border-training vertical align-items-start px-5">
            <div className="mx-md-5 px-md-5">
              <h6 className="text-uppercase"> Technozor</h6>
              <h2 className="gradient-text fw-bold">
                Why <br /> Attend <br />
                This Training?
              </h2>
            </div>
          </div>
          <div className="col-md-5 d-flex p-5 p-md-0">
            <img
              alt="apply-training-img"
              src="/assets/images/training/typing-img.png"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row align-items-stretch gradient-border-training horizontal">
          <div className="col-md-7 gradient-border-training vertical op p-md-5">
            <TrainingCards />
          </div>
          <div className="col-md-5 d-flex flex-column  justify-content-center  p-md-5 py-0">
            <div className="px-5">
              <p className="pb-4">
                As businesses in Kenya increasingly recognize the power of data,
                the need for structured governance and a clear data strategy has
                never been more critical. This training will empower you to take
                charge of your data, optimize decision-making, and ensure
                compliance, positioning you as a leader in your field.
              </p>
              {/* <h6 className="text-uppercase pt-5 pb-3">
                how we worked together
              </h6> */}
              {/* <p>
                Technozor develops comprehensive hardware, software, content, and
                in-person events that integrate robotics, computer science, and
                education for students across the world.
              </p> */}
              {/* <h6 className="text-uppercase pt-5 pb-3">
                year of first investment
              </h6>
              <h6 className="text-uppercase pb-3">2020</h6> */}
              <Link className="link" to="/">
                <span className="text-uppercase fs-3 fw-bold gradient-text">
                  Technozor
                  <img
                    src="/assets/images/program/Arrow 2.svg"
                    alt="arrow right"
                    className="ms-2"
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl px-5 px-md-0">
        <div className="row apply-training align-items-center text-center">
          <div className="col-md-6 training-text">
            <h3>
              Who Should Attend <br />
              This Training ?
            </h3>
            <ul>
              <li>Data Analysts, Scientists, and Engineers</li>
              <li>IT Managers and Data Governance Professionals</li>
              <li>Business Leaders and Decision Makers</li>
              <li>Compliance and Risk Officers</li>
              <li>
                Anyone involved in managing, analyzing, or leveraging data in
                their organization
              </li>
            </ul>
            {/* <button className="btn mt-4">Subscribe</button> */}
          </div>
          <div className="col-md-6">
            <img
              alt="apply-training-img"
              src="/assets/images/training/apply-training-img.png"
              className="img-fluid py-5 mt-5 mt-md-0"
            />
          </div>
        </div>
      </div>

      <div className="container">
        {/* Customers */}
        <div className="line my-5"></div>
        <div className="">
          <h2 className="dark-blue mb-5">Our Customers and Partners</h2>
          <div className="container mt-5">
            <div className="row justify-content-center p-5">
              {Array.from(Array(9), (e, index) => {
                return (
                  <div
                    className="col-12 col-md-4 col-lg-3 d-flex align-items-center justify-content-center customers mb-4"
                    key={index}
                  >
                    <img
                      src={`/assets/images/customers/${
                        !isDarkMode ? "image" : "banner"
                      } ${index + 1}.png`}
                      alt={`Customer ${index + 1}`}
                      className="img-fluid p-5"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="line mt-5"></div>
        <GetInTouch />
      </div>
    </>
  );
};

export default Training;
